import './vendor/jquery.easing.1.3.js';
var Masonry = require('masonry-layout');
import './vendor/smoothzoom.js';

var t3h = (function () {
    'use strict';

    return {
        masonry: function () {
            var msnry = new Masonry('.masonry');

            return msnry;
        },
        popup: function () {
            $('a.popup').on('click', function (e) {
                e.preventDefault();
                var cls = $(this).attr('class');
                var url = $(this).attr('href');
                var w = (cls.match(/w:(\d+)/) || [])[1] || 600;
                var h = (cls.match(/h:(\d+)/) || [])[1] || 410;
                var leftPos = screen.width ? (screen.width - w) / 2 : 0;
                var topPos = screen.height ? (screen.height - h) / 2 : 0;
                var parm =
                    'width=' +
                    w +
                    ', height=' +
                    h +
                    ', top=' +
                    topPos +
                    ', left=' +
                    leftPos +
                    ', scrollbars=1, resizable=1';

                if (typeof ga == 'function') {
                    ga('send', 'event', 'Links', 'Popup', url);
                }

                window.open(url, 'InfoWindow', parm).focus();
            });
        },
        smoothZoom: function () {
            var $elm = $('.can-zoom');
            if ($elm.length > 0) {
                $('.can-zoom').smoothZoom({
                    zoominSpeed: 100,
                    zoomoutSpeed: 100,
                });
            }
        },
        keyboardNavigation: function () {
            var $next = $('a[rel=next]');
            var $prev = $('a[rel=prev]');

            if (typeof $next !== 'undefined' || typeof $prev !== 'undefined') {
                var $nextURL = $next.attr('href');
                var $prevURL = $prev.attr('href');

                $('body').on('keydown.t3hwin', function (e) {
                    switch (e.which) {
                        case 37:
                            if (typeof $nextURL !== 'undefined') {
                                document.location = $nextURL;

                                if (typeof ga == 'function') {
                                    ga('send', 'event', 'Page Navigation', 'NextPrevious', 'Next arrow pressed');
                                }
                            } else {
                                return;
                            }
                            break;

                        case 39:
                            if (typeof $prevURL !== 'undefined') {
                                document.location = $prevURL;

                                if (typeof ga == 'function') {
                                    ga('send', 'event', 'Page Navigation', 'NextPrevious', 'Previous arrow pressed');
                                }
                            } else {
                                return;
                            }
                            break;

                        default:
                            return;
                    }
                    e.preventDefault();
                });

                $next.on('click', function () {
                    if (typeof ga == 'function') {
                        ga('send', 'event', 'Page Navigation', 'NextPrevious', 'Next arrow clicked');
                    }
                });

                $prev.on('click', function () {
                    if (typeof ga == 'function') {
                        ga('send', 'event', 'Page Navigation', 'NextPrevious', 'Previous arrow clicked');
                    }
                });
            }
        },
        trackLinks: function () {
            var $next = $('a.next');
            var $prev = $('a.prev');
            var $pages = $('a.page-numbers:not(.prev):not(.next)');
            var $related = $('.js-media_related');
            var $hdNav = $('#js-hd-nav-track');

            $next.on('click', function () {
                if (typeof ga == 'function') {
                    ga('send', 'event', 'Page Navigation', 'NextPrevious', 'Next link clicked');
                }
            });

            $prev.on('click', function () {
                if (typeof ga == 'function') {
                    ga('send', 'event', 'Page Navigation', 'NextPrevious', 'Previous link clicked');
                }
            });

            $pages.on('click', function () {
                var $this = $(this);

                if (typeof ga == 'function') {
                    ga('send', 'event', 'Page Navigation', 'Pages', 'Page ' + $this.html() + ' clicked');
                }
            });

            $related.on('click', function () {
                var $this = $(this);

                if (typeof ga == 'function') {
                    ga('send', 'event', 'Related Images', 'Related', $this.attr('title'));
                }
            });

            $hdNav.on('click', 'a', function () {
                var $url = $(this).attr('href');

                if (typeof ga == 'function') {
                    ga('send', 'event', 'Navigation', 'Main', $url);
                }
            });
        },
        mobileNavigation: function () {
            var $elm = $('#js-nav-mob');
            var $nav = $('#js-nav-mob-wrp');

            if ($elm.length > 0) {
                var $hdNav = $('#js-hd-nav > ul').clone();
                var $hdSearch = $('#js-hd-search').clone();
                var $hdSocial = $('#js-hd-nav-soc').clone();

                $elm.on('click', function (e) {
                    e.preventDefault();

                    if ($nav.hasClass('hd-nav_side-visible')) {
                        $nav.animate(
                            {
                                left: $nav.attr('data-initial'),
                            },
                            300,
                            function () {
                                $nav.removeClass('hd-nav_side-visible');
                            }
                        );

                        if (typeof ga == 'function') {
                            ga('send', 'event', 'Mobile Navigation', 'MobNavVisibility', 'Hidden');
                        }
                    } else {
                        $nav.addClass('hd-nav_side-visible');

                        $nav.append($hdNav);
                        $nav.append($hdSearch);
                        $nav.append($hdSocial);

                        $nav.attr('data-initial', $nav.css('left'));
                        $nav.animate(
                            {
                                left: '0',
                            },
                            300
                        );

                        if (typeof ga == 'function') {
                            ga('send', 'event', 'Mobile Navigation', 'MobNavVisibility', 'Visible');
                        }
                    }
                });
            }
            $(document).mouseup(function (e) {
                var $navVisible = $nav.css('left');

                if (!$nav.is(e.target) && $nav.has(e.target).length === 0 && $navVisible === '0px') {
                    $nav.animate(
                        {
                            left: $nav.attr('data-initial'),
                        },
                        300,
                        function () {
                            $nav.removeClass('hd-nav_side-visible');
                        }
                    );

                    if (typeof ga == 'function') {
                        ga('send', 'event', 'Mobile Navigation', 'MobNavVisibility', 'Hidden');
                    }
                }
            });
        },
        descriptionLinks: function () {
            $('.media_item .media_item-description a').each(function (key, value) {
                $(value)
                    .attr({
                        rel: 'nofollow external',
                    })
                    .removeAttr('title')
                    .removeAttr('dir')
                    .removeAttr('class')
                    .removeAttr('target')
                    .on('click', function (e) {
                        e.preventDefault();

                        if (typeof ga == 'function') {
                            ga('send', 'event', 'Links', 'External link', this.href);
                        }

                        window.open(this.href);
                    });
            });
        },
        externalLinks: function () {
            $('a[rel*="external"]').on('click', function (e) {
                e.preventDefault();

                if (typeof ga == 'function') {
                    ga('send', 'event', 'Links', 'External link', this.href);
                }

                window.open(this.href);
            });
        },
        init: function () {
            t3h.masonry();
            t3h.popup();
            t3h.smoothZoom();
            t3h.keyboardNavigation();
            t3h.trackLinks();
            t3h.mobileNavigation();
            t3h.descriptionLinks();
            t3h.externalLinks();
        },
    };
})();

t3h.init();
